const menuHamburger = document.querySelector(".menu-hamburger")
const navLinks = document.querySelector(".nav-links")

menuHamburger.addEventListener('click', () => {
    navLinks.classList.toggle('mobile-menu');
});

document.addEventListener("DOMContentLoaded", function() {
    // get current URL path and assign 'active' class
    let pathname = window.location.pathname
    console.log('.nav-links > ul > li > a[href="'+pathname+'"]')
    document.querySelector('.nav-links > ul > li > a[href="'+pathname+'"]').parentNode.classList.add("active")
})